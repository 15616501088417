import * as React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GetPlansYear, getQuoteId, getStoredUser } from '@coverright/utils';
import { CRPreloader, PageWrapper } from '@coverright/ui/shared';
import { environment } from '../../../environments/environment';
import ShortPlanCard from './ShortPlanCard';
import paths from '../../../config/router-paths';
import { useMaPlansCount, useMedicarePlan } from '@coverright/data-access/medicare';
import MAMarketplaceLayout from '../../components/MAMarketplaceLayout';
import { useCalendlyModal } from '@coverright/features';
import ThankYouModal from '../../components/ThankYouModal';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

export function Completed() {
  const { planId, zip, countyName } = useParams<{
    zip: string;
    countyName: string;
    planId: string;
  }>();
  const navigate = useNavigate();

  const { maPlansCount, maOrgCount } = useMaPlansCount(zip);
  const [getPlan, { data }] = useMedicarePlan();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const openCalendly = useCalendlyModal();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (environment.production) {
      const iframe = document.createElement('iframe');
      iframe.src =
        '//insurance.mediaalpha.com/p/i1iiUDWOSVcJWnvXMO90OYZ-LkQO5Q?u=3';
      iframe.width = '1';
      iframe.height = '1';
      iframe.frameBorder = '0';
      document.body.appendChild(iframe);
    }
  }, [environment.production]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          countyName,
          zip,
          quoteId: getQuoteId(),
          planYear: GetPlansYear(),
        },
      });
    }
  }, [planId, zip, getPlan]);

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  const onBackButtonClick = () => {
    navigate(
      paths.plans + `${getStoredUser()?.zip}/${getStoredUser()?.countyName}`
    );
  };

  if (!plan) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CRPreloader />
      </Box>
    );
  }

  return (
    <MAMarketplaceLayout
      backButtonTitle={'Return to plans'}
      onBackButtonClick={onBackButtonClick}
      main={
        <PageWrapper
          title={'Congratulations on selecting a new Medicare Advantage plan!'}
          subtitle={'Here is the plan you selected:'}
          maxWidth={800}
          onNextButtonClick={() => {
            openCalendly({
              onEventScheduled: () => {
                setOpen(true);
              },
            });
          }}
          nextButtonTitle={'Connect now'}
          formName={'MAComplete'}
          disclamer={<>
            <Typography className={'fs-12 mt-16'} color={'textPrimary'}>
              Disclaimers:<br />
              Every year, Medicare evaluates plans based on a 5-star rating system.
              <br /><br />
              CoverRight Insurance Services Inc. represents Medicare Advantage HMO, PPO and PFFS organizations and
              stand-alone PDP prescription drug plans that have a Medicare contract. Enrollment depends on the plan’s
              contract renewal with Medicare.<br />
              We do not offer every plan available in your area. Currently we represent {maOrgCount} organizations which offer {maPlansCount} products in your area. Please contact Medicare.gov,
              1–800–MEDICARE, or your local State Health Insurance Program (SHIP) to get information on all of your
              options.

            </Typography>
          </>}
        >
          <ThankYouModal
            open={open}
            onClose={() => {
              setOpen(false);
              onBackButtonClick();
            }}
          />
          <ShortPlanCard plan={plan} />
          <Box
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'center', mg: 'flex-start' }}
            className={'flex mt-30 mb-40'}
          >
            <img
              className={(!phone ? 'mr-20 ' : '') + 'w-110 h-110'}
              src={'/assets/img/speak-to-advisor.svg'}
            />
            <div className={phone ? 'mt-20' : ''}>
              <Typography className={'fs-26 bold lh-32 mb-8'}>
                Connect with your dedicated CoverRight licensed insurance agent to complete your
                enrollment.
              </Typography>
              <Typography className={'fs-20 lh-25'}>
                Your licensed insurance agent will collect some additional information and
                confirm your eligibility before submitting your application.
              </Typography>
            </div>
          </Box>
        </PageWrapper>
      }
    />
  );
}

export default Completed;
