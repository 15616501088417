import {forwardRef} from "react";
import {InputProps, TextInput} from "./text-input";
import * as React from "react";
import * as _ from "lodash";

interface FormikTextInputProps extends InputProps {
  formik: any,
  name: string,
  'data-test'?: string,
}

export const FormikTextInput = forwardRef((props: FormikTextInputProps, ref: any) => {
  const {formik, name, ...rest} = props;

  return <TextInput
    ref={ref}
    value={formik.values[name]}
    onChange={formik.handleChange}
    error={Boolean(formik.errors[name])}
    helperText={formik.errors[name]}
    name={name}
    label={rest.label || _.startCase(name)}
    data-test={props['data-test'] || _.snakeCase(name)}
    placeholder={rest.placeholder || _.startCase(name)}
    {...rest}
  />
})
