import React from 'react';
import { Box, Rating, Tooltip, Typography } from '@mui/material';
import { Star } from '@phosphor-icons/react';
import { tooltips } from '@coverright/utils';

interface StarRatingProps {
  plan?: { rating?: number | null, ratingNote?: string | null },
  size?: number,
  endAdornment?: JSX.Element,
  labelClassName?: string
}

export function StarRating({plan, size = 24, endAdornment, labelClassName}: StarRatingProps) {
  if (!plan || !plan.rating && !plan.ratingNote) {
    return null;
  }

  const rating = !plan.rating ? <Typography sx={{color: '#4D4D4D'}} className={`no-wrap medium fs-14 ${labelClassName || ''}`} >{plan.ratingNote}</Typography>
    : <Rating emptyIcon={<Star size={size} style={{minWidth: size}} color="#1e92a0" weight="duotone" />}
              icon={<Star size={size} style={{minWidth: size}} color="#1e92a0" weight="fill" />}
              defaultValue={plan.rating || 0}
              precision={.5}
              readOnly
    />;

  return <Tooltip arrow title={tooltips.starRating}>
    <Box sx={{width: 'fit-content'}} className={'flex flex-align-center'}>
      {rating}
      {endAdornment}
    </Box>
  </Tooltip>;
}
