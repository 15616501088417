import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';
import { CreateAccountForm } from '@coverright/widgets';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import paths from '../../../config/router-paths';
import { getStoredUser } from '@coverright/utils';
import { useMaPlansCount } from '@coverright/data-access/medicare';

export function SignUpPersonalInfo() {
  const navigate = useNavigate();
  const { maPlansCount, maOrgCount } = useMaPlansCount(getStoredUser()?.zip);

  const onSubmit = () => {
    navigate(
      paths.plans +
      getStoredUser()?.zip +
      '/' +
      getStoredUser()?.countyName +
      document.location.search
    );
  };

  return (
    <div>
      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <Box sx={{ p: '40px', width: 1, maxWidth: 609 }}>
          <Typography
            className={'bold'}
            align={'center'}
            sx={{ fontSize: 26, lineHeight: '32px' }}
          >
            Provide your contact information
          </Typography>
          <div className={'h-40'} />
          <CreateAccountForm
            onLoadingChange={() => {
            }}
            columns={1.5}
            signInLink={''}
            onSuccess={onSubmit}
            setFormik={() => {
            }}
            source={ProfileSource.MaMarketplace}
            showPhone
          />
          <Box sx={{ height: '40px' }} />
          <div>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
              <img src={'/assets/img/question.svg'} width={24} height={24} />
              <Typography className={'fs-16 lh-24 bold'}>
                We never sell your information
              </Typography>
            </Box>
            <Typography sx={{ color: '#4D4D4D' }} className={'fs-16 lh-24'}>
              We never sell your information or personal details to third
              parties. Your information is stored on HIPAA-compliant data
              storage with 256-bit encryption.
            </Typography>
          </div>
          <Box sx={{ height: '20px' }} />
          <Button
            sx={{ minWidth: 250, width: '100%', borderRadius: '8px' }}
            onClick={() => onSubmit()}
            variant={'contained'}
            size={'large'}
            color={'primary'}
            type="submit"
          >
            Continue
          </Button>
        </Box>
      </Box>

      <div>
        <Box sx={{ maxWidth: 586, m: 'auto' }}>
          <div className={'ph-35 mt-16'}>
            <Typography className={'fs-11 lh-13 thin'} color={'textPrimary'}>
              By submitting my phone number and personal information above and clicking ‘Submit’ above, I confirm that
              the phone number provided is my own, and I provide my express written consent to be contacted by
              CoverRight Insurance Services Inc. or one of its licensed insurance agents at the phone
              number I have provided above regarding various Medicare products including Medicare Advantage Plans,
              Medicare Supplement (Medigap) Plans, Prescription Drug Plans and other marketing and informational
              content, through the use of text message, email and/or live, automated and pre-recorded telephone calls,
              AI generative voice calls, prerecorded messages played when a connection is made, or prerecorded voicemail
              messages even if my number is on a Do Not Call list. I further understand and agree that my communications
              with CoverRight Inc. are subject to CoverRight Inc.'s Privacy Policy and Terms of Use.
              <br />
              <br />
              This is a solicitation for insurance. Your information will be
              provided to a licensed agent for future contact.
              <br />
              <br />
              I understand my consents to receive text messages and telephone
              calls are not required to receive services from CoverRight Inc.; I
              may instead reach CoverRight Inc. directly at +1 (888) 969-7667
              (TTY: 711) and I can revoke my consents at any time by emailing
              hello@coverright.com.
              <br />
              <br />
              CoverRight Insurance Services Inc. represents Medicare Advantage
              HMO, PPO and PFFS organizations that have a Medicare contract.
              Enrollment depends on the plan’s contract renewal with Medicare.
              <br />
              <br />
              We do not offer every plan available in your area. Currently we represent {maOrgCount} organizations which offer {maPlansCount} products in your area. Please contact Medicare.gov, 1–800–MEDICARE, or
              your local State Health Insurance Program (SHIP) to get information on all of your options
              <br />
              <br />
              MULTIPLAN_COVERRIGHT_2025_M
            </Typography>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default SignUpPersonalInfo;
