import React from "react";
import { Box, Card, Divider, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import moment from "moment";
import { StyledRating, TooltipedTitle, PlanParam } from '@coverright/ui/marketplaces';
import { Tooltip } from '@coverright/ui/inputs';
import { colors } from '@coverright/ui/themes';
import { getCompanyLogo, GetPlansYear } from '@coverright/utils';
import { createStyles, makeStyles } from '@mui/styles';
import { EnrollmentOutput, StatusOutput } from '@coverright/data-access/types/enrollment';
import { Plan } from '@coverright/data-access/types/medicare';

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      fontWeight: 500,
      color: colors.text.primary,
      marginTop: 3
    },
    white: {
      color: 'white',
      fontWeight: 700,
    },
    logo: {
      height: 33,
      minHeight: 33,
      width: 119,
      minWidth: 119,
      marginTop: '9px',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right'
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '15px 0 0',
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '0 14px 10px 16px',
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)',
    },
    enrollSection: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 195,
      },
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '16px 9px 16px 16px',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    medium: {
      fontWeight: 500,
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      marginRight: 20,
      color: 'rgba(28, 67, 79, 1)',
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14,
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14,
    },
    summaryButton: {
      padding: 4,
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important',
      }
    },
    enrollButton: {
      fontSize: 14,
      width: '100%',
      padding: 11,
    },
    detailsEnrollButton: {
      padding: '11px 44px',
    },
    excl: {
      fontSize: 12,
      color: colors.custom.green.variant2
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    pharmacyLabelClass: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.text.primary
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    },
    enrollmentStatusSection: {
      height: 55,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 19,
      paddingRight: 11
    },
    pending: {
      background: '#778E95',
      color: 'white',
      padding: '7px 30px',
      borderRadius: 10,
      fontSize: 16,
      fontWeight: 700,
    },
    benefits: {
      padding: '16px 15px 16px 27px',
      background: '#E9F1F0',

    }
  }),
);

type PlanCardProps = {
  plan: Plan,
  enrollment?: EnrollmentOutput,
  year?: string
}

const tooltipImgProps = {
  width: 12,
  height: 12,
}

function ShortPlanCard({plan, enrollment, year} : PlanCardProps) {
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const getStatusContent = (status: StatusOutput): JSX.Element => {
    switch (status) {
      case StatusOutput.Draft: return <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16 mr-25'} color={'textPrimary'}>Application date: {moment(enrollment?.createdAt).format('MM/DD/YY')}</Typography>
        <div className={classes.pending}>Pending</div>
      </Box>;

      case StatusOutput.Enrolled: return <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16 mr-8 bold'} color={'textPrimary'}>Enrolled ({moment(enrollment?.approvedAt).format('MM/DD/YY')})</Typography>
        <img src={'/assets/img/green2-check.svg'} width={40} height={40} />
      </Box>;

      default: return <></>;
    }
  }

  const rating = !plan.rating ? <Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography>
    : <StyledRating size={'medium'} precision={0.5} readOnly defaultValue={plan.rating || 0}/>;

  const Desktop = <Card className={classes.card}>
    <div className={'flex mh-15 mb-15'}>
      <div className={classes.infoWrapper}>
        <Box minHeight={80} className={'flex-space-between flex-align-center'}>
          <div className={'mt-8'}>
            <Box display={'flex'} alignItems={'center'}>
              <TooltipedTitle title={rating}
                              tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}
                              imgProps={{
                                className: `w-12 h-12 ${!plan.rating ? 'mt-5' : 'mt-16'}`,
                              }}/>
              {!!plan.rating && <Typography className={'medium fs-12 mt-5 ml-8'} color={'textPrimary'}>({year || GetPlansYear().toString().replace('Year', '')})</Typography>}
            </Box>
            <Typography variant={'h2'} color={'textPrimary'} className={'bold mt-8'}>{plan?.planName}</Typography>
            <div className={'h-10'} />
            <Typography variant={'h4'} color={'textSecondary'}>Plan ID: {plan.bidId}</Typography>
          </div>
          <div>
            <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
          </div>
        </Box>
        <Divider sx={{my: 2}} />
        <div className={'flex'}>
          <div className={'flex-column'}>
            <TooltipedTitle title={'Monthly Plan Premium'} tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'} imgProps={tooltipImgProps}/>
            <PlanParam label={''} value={plan.monthlyPremium || '$0'} />
          </div>
          <div className={'flex-column ml-20'}>
            <TooltipedTitle title={'Deductibles'} tooltip={'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'} imgProps={tooltipImgProps}/>
            <div className={'flex'}>
              <div className={'flex-column'}>
                <PlanParam label={'Medical'} value={`${plan?.inNetworkDeductableAmount || '$0'}`} />
              </div>
              <div className={'flex-column'}>
                <PlanParam label={'Drug'} value={`${plan?.drugDetails?.mrxAltDedAmount || 'N/A'}`} />
              </div>
            </div>
          </div>
          <div className={'flex-column ml-20'}>
            <div className={'mb-4'}>
              <TooltipedTitle title={'Doctor & Hospital Costs'} tooltip={'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'} imgProps={tooltipImgProps}/>
            </div>
            <PlanParam label={'Primary doctor (In-network)'} value={plan?.primaryCarePhysicianShort || 'N/A' } />
          </div>
        </div>
      </div>
      <div className={classes.enrollSection}>
        <div className={'flex-column'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className={'fs-36 bold mr-20'}>{plan.monthlyCost || 'N/A'}</Typography>
            <Tooltip placement={'top'} arrow title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this form for a full description of the breakdown of these costs.'}>
              <img width={21} height={21} src={'/assets/img/info-green.svg'} />
            </Tooltip>
          </Box>
          <Typography variant={'body2'} color={'textPrimary'} className={classes.bold}>estimated average total cost per month</Typography>
          <div className={'mt-15 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Monthly Plan Premium:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.monthlyPremium}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. drug costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.drugsCost || 'N/A'}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. doctor costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.doctorsCost}</Typography>
          </div>
          <div className={'mt-8'}>
            <Tooltip placement={'top'} arrow title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan'}>
              <Typography  className={classes.excl}>Excl. standard Part B premium</Typography>
            </Tooltip>
            {plan.optionalSupplementalBenefits && <div>
              <Typography className={classes.excl}>Excl. optional benefits</Typography>
            </div>}
          </div>
        </div>
      </div>
    </div>
    {!!enrollment && <div className={classes.enrollmentStatusSection} style={{backgroundColor: getStatusBackground(enrollment?.status) }}>
      <Typography className={'bold fs-16'} color={'textPrimary'}>Enrollment status</Typography>
      {getStatusContent(enrollment?.status)}
    </div>}
  </Card>

  const Mobile = <Card className={classes.card}>
    <div className={'mh-15 mb-15'}>
        <Box className={'flex-space-between flex-align-start'}>
          <div>
            <div className={classes.logo} style={{backgroundPosition: 'left', marginTop: 0, backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
          </div>
          <div>
            {rating}
            <TooltipedTitle title={<Typography className={'medium fs-12'} align={'right'}>({GetPlansYear().toString().replace('Year', '')})</Typography>}
                            containerClassName={'flex-justify-end mt-5'}
                            tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}
                            imgProps={{
                              className: `w-16 h-16`,
                            }}/>
          </div>
        </Box>
        <Typography variant={'h2'} color={'textPrimary'} className={'bold mt-8'}>{plan?.planName}</Typography>
        <div className={'h-10'} />
        <Typography variant={'h4'} color={'textSecondary'}>Plan ID: {plan.bidId}</Typography>

        <Box sx={{my: 2}} className={classes.enrollSection}>
          <div className={'flex-column'}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography className={'fs-36 bold mr-20'}>{plan.monthlyCost || 'N/A'}</Typography>
              <Tooltip placement={'top'} arrow title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this form for a full description of the breakdown of these costs.'}>
                <img width={21} height={21} src={'/assets/img/info-green.svg'} />
              </Tooltip>
            </Box>
            <Typography variant={'body2'} color={'textPrimary'} className={classes.bold}>estimated average total cost per month</Typography>
            <div className={'mt-15 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Monthly Plan Premium:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.monthlyPremium}</Typography>
            </div>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Est. drug costs:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.drugsCost || 'N/A'}</Typography>
            </div>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Est. doctor costs:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.doctorsCost}</Typography>
            </div>
            <div className={'mt-8'}>
              <Tooltip placement={'top'} arrow title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan'}>
                <Typography  className={classes.excl}>Excl. standard Part B premium</Typography>
              </Tooltip>
              {plan.optionalSupplementalBenefits && <div>
                <Typography className={classes.excl}>Excl. optional benefits</Typography>
              </div>}
            </div>
          </div>
        </Box>

        <div>
          <div className={'flex-column'}>
            <TooltipedTitle title={'Monthly Plan Premium'} tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'} imgProps={tooltipImgProps}/>
            <PlanParam label={''} value={plan.monthlyPremium || '$0'} />
          </div>
          <Divider sx={{my: 2}} />
          <div className={'flex-column'}>
            <TooltipedTitle title={'Deductibles'} tooltip={'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'} imgProps={tooltipImgProps}/>
            <div className={'flex'}>
              <div className={'flex-column'}>
                <PlanParam label={'Medical'} value={`${plan?.inNetworkDeductableAmount || '$0'}`} />
              </div>
              <div className={'flex-column'}>
                <PlanParam label={'Drug'} value={`${plan?.drugDetails?.mrxAltDedAmount || 'N/A'}`} />
              </div>
            </div>
          </div>
          <Divider sx={{my: 2}} />
          <div className={'flex-column'}>
            <div className={'mb-4'}>
              <TooltipedTitle title={'Doctor & Hospital Costs'} tooltip={'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'} imgProps={tooltipImgProps}/>
            </div>
            <PlanParam label={'Primary doctor (In-network)'} value={plan?.primaryCarePhysicianShort || 'N/A' } />
          </div>
        </div>

    </div>
    {!!enrollment && <div className={classes.enrollmentStatusSection} style={{backgroundColor: getStatusBackground(enrollment?.status) }}>
      <Typography className={'bold fs-16'} color={'textPrimary'}>Enrollment status</Typography>
      {getStatusContent(enrollment?.status)}
    </div>}
  </Card>

  return <div>
    {phone ? Mobile : Desktop}
  </div>;
}

export default React.memo(ShortPlanCard);

const getStatusBackground = (status: StatusOutput): string => {
  switch (status) {
    case StatusOutput.Draft: return `#E8ECED`;
    default: return `rgba(2, 144, 148, 0.1)`;
  }
}

interface PlanParamProps {
  value: string | JSX.Element
  label?: string,
}
export {PlanParam};
