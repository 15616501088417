'use client';
import * as React from 'react';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { TextInput } from '@coverright/ui/inputs/text-input';
import { SelectInput } from '@coverright/ui/inputs/select-input';
import { useState } from 'react';
import { IZipCountyFips, useZipCountyFipsLazy } from '@coverright/data-access/medicare';

type Location = {
  zip: string;
  county: string;
  state: string;
};

interface Props {
  zip?: string;
  county?: string;
  state?: string;
  onChange: (location: Location) => void;
}

export function ZipSelectorCompact({ onChange, zip = '', state = '', county = '' }: Props) {
  const [location, setLocation] = useState<Location>({
    zip,
    county,
    state,
  });
  const [counties, setCounties] = useState<IZipCountyFips[]>([]);
  const inpRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const [getCounties, { loading, called }] = useZipCountyFipsLazy({
    onCompleted: (data) => {
      const result = data.plans_zip_county_fips;
      if (result.length === 1) {
        setLocation((prev) => {
          const newValue = {
            ...prev,
            county: result[0].countyname,
            state: result[0].state,
          }
          onChange(newValue);
          return newValue
        });
      } else if (result.length > 1) {
        setOpen(true);
      }
      setCounties(result);
    },
  });

  const handleInputChange = async (value: string) => {
    setLocation({ zip: value, county: '', state: '' });
    setCounties([]);
    if (isValid(value)) {
      await getCounties({ variables: { zip: value } });
    }
  };

  const handlePopoverChange = (value: string) => {
    const county = counties.find((c) => c.countyname === value);
    if (county) {
      setLocation((prev) => {
        const newValue = {
          ...prev,
          county: county.countyname,
          state: county.state,
        }
        onChange(newValue);
        return newValue
      });
    }
    setOpen(false);
  };

  const handlePopoverClose = () => {
    if (!location.county) {
      setLocation({ zip: '', county: '', state: '' });
      onChange({ zip: '', county: '', state: '' });
    }
    setOpen(false);
  };

  const error =
    /^[0-9]{5}$/.test(location.zip) && !loading && called && !counties.length && !location.county
      ? "We can't find your county"
      : undefined;

  const endAdornment = location.county ? (
    <InputAdornment
      sx={{
        '& p': { maxWidth: 125, overflow: 'hidden', textOverflow: 'ellipsis' },
      }}
      position="end"
    >
      <Typography variant={'body2'} sx={{ color: '#475467' }}>
        {location.county}
      </Typography>
    </InputAdornment>
  ) : undefined;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          gap: 1,
          maxWidth: { md: 504 },
        }}
      >
        <TextInput
          placeholder={'Enter your zip code'}
          ref={inpRef}
          autoFocus
          hideTick
          value={location.zip}
          sx={{ mb: 0 }}
          loading={loading}
          error={!!error}
          helperText={error}
          inputProps={{
            maxLength: 5,
          }}
          InputProps={{
            endAdornment,
            onFocus: e => {
              e.target.select();
            }
          }}
          type={'tel'}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        <CountiesPopover
          counties={counties}
          onClose={handlePopoverClose}
          value={location.county}
          anchorEl={inpRef.current}
          onChange={handlePopoverChange}
          open={open}
        />
      </Box>
    </>
  );
}

interface PopoverProps {
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  counties: IZipCountyFips[];
  open: boolean;
  value: string;
  onChange: (value: string) => void;
}

const CountiesPopover = ({
  onClose,
  counties,
  anchorEl,
  open,
  onChange,
  value,
}: PopoverProps) => (
  <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <Box p={1} width={'200px'}>
      <SelectInput
        fullWidth
        hideTick
        wrapperSx={{ '& .MuiTextField-root': { mb: 0 } }}
        sx={{
          '& div.MuiOutlinedInput-input': {
            m: '5px 2px 1px!important',
            p: '8px 15px 6px!important',
          },
        }}
        label={'Select county'}
        value={value}
        placeholder={'Select county'}
        onChange={(event) => {
          onChange(event.target.value as string);
        }}
      >
        {counties.map((county) => (
          <MenuItem key={county.countyname} value={county.countyname}>
            {county.countyname}
          </MenuItem>
        ))}
      </SelectInput>
    </Box>
  </Popover>
);

const isValid = (value: any) => /^[0-9]{5}$/.test(String(value));
