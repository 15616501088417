import React from 'react';
import moment from 'moment';
import { CRModal } from '@coverright/ui/shared/crmodal';
import { Box, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react/dist/ssr/X';

interface Props {
  plansCount: string | number,
  orgCount: string | number,
  open: boolean,
  onClose: () => void
}

export function MedicareDisclaimersModal(props: Props) {
  return <CRModal
    width={740}
    open={props.open}
    onClose={props.onClose}
    paperPadding={'30px 0px 40px'}
  >
    <>
      <IconButton sx={{
        position: 'absolute',
        top: 0,
        right: 0
      }} onClick={props.onClose}><X size={24} color="#000" weight="regular" /></IconButton>
      <Box sx={{ px: '30px' }}>
        <Typography color={'textPrimary'} className={'bold fs-24'}>Medicare Disclaimers</Typography>
        <Typography color={'textPrimary'} className={'fs-14'}>
          <br /><br />
          <b>General Disclaimer</b>
          <br /><br />
          Estimated doctor cost is calculated based on your estimated number of Primary Care Physician (PCP) and
          Specialist visits per year, multiplied by the copays or coinsurance for in-network providers as outlined in
          the plan details. The reference price for the coinsurance calculation (for both in-network PCP and Specialist
          office visits) is based on the national average cost for an office consultation for an established patient,
          typically 25 minutes in length. These calculations are estimated based on the information you provide and may
          not accurately represent potential costs under the plan if incomplete information is provided. Actual costs
          may vary.
          <br /><br />
          Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for the
          medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the individual
          drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference price (where
          applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated monthly drug
          costs can only be calculated if you have entered medication information. These calculations are estimated
          based on the information you provide and may not accurately represent potential costs under the plan if
          incomplete information is provided. Actual costs may vary.
          <br /><br />

          <b>Medicare Disclaimers</b>
          <br /><br />
          CoverRight Insurance Services Inc., is a licensed and certified representative of Medicare Advantage [HMO, PPO
          and PFFS] organizations [and stand-alone prescription drug plans] with a Medicare contract. Enrollment in any
          plan depends on contract renewal with Medicare.
          <br /><br />
          We do not offer every plan available in your area. Currently we represent {props.orgCount}
          organizations which offer {props.plansCount} products in your area. Please contact Medicare.gov,
          1–800–MEDICARE, or your local State Health Insurance Program (SHIP) to get information on all of your options.
          <br /><br />
          For a complete list of available plans please contact 1-800-MEDICARE (1-800-633-4227). TTY/RTT users should
          call 1-877-486-2048, 24 hours a day/7 days a week or consult Medicare.gov.
          <br /><br />
          Medicare beneficiaries can file a complaint with the Centers for Medicare & Medicaid Services by calling
          1-800-MEDICARE 24 hours a day/7 days a week or using the medicare.gov site.
          <br /><br />
          Every year, Medicare evaluates plans based on a 5-star rating system.
          <br /><br />
          Enrollees must use network providers for specific services when stated in the evidence of coverage, otherwise,
          covered services received from non-network providers may be a higher cost or not eligible.
          <br /><br />
          Out-of-network/non-contracted providers are under no obligation to treat Plan members, except in emergency
          situations. Please call our customer service number or see your Evidence of Coverage for more information,
          including the cost-sharing that applies to out-of-network services.
          <br /><br />
          Enrollees must continue to pay the Medicare Part B premium, and the plan premium plus the optional
          supplemental plan premium, if applicable.
          <br /><br />
          Benefits, premiums, co-payments/co-insurance may change on January 1 of each year.
          <br /><br />
          Please call your Plan or refer to your Evidence of Coverage for more information, about eligible benefits and
          cost-sharing applicable to in-network and out-of-network services.
          <br /><br />
          You must have both Part A and B to enroll in a Medicare Advantage plan. Members may enroll in the plan only
          during specific times of the year. Contact the plan for more information.
          <br /><br />
          Not all plans offer all of these benefits. Benefits may vary by carrier and location. Limitations and
          exclusions may apply.
          <br /><br />
          Part B Premium give-back is not available with all plans. Availability varies by carrier and location. Actual
          Part B premium reduction could be lower. Deductibles, copays and coinsurance may apply.
          <br /><br /><br /><br />
          <b>Health Plan Disclaimers</b>
          <br /><br />
          <b>Humana Specific Disclaimers:</b>
          <br /><br />
          Important
          <br /><br />
          At Humana, it is important you are treated fairly.
          <br /><br />
          Humana Inc. and its subsidiaries comply with applicable Federal civil rights laws and do not discriminate on
          the basis of race, color, national origin, ancestry, ethnicity, sex, sexual orientation, gender, gender
          identity, disability, age, marital status, religion, or language in their programs and activities, including
          in admission or access to, or treatment or employment in, their programs and activities.
          <br /><br />
          The following department has been designated to handle inquiries regarding Humana’s non-discrimination
          policies: Discrimination Grievances, P.O. Box 14618, Lexington, KY 40512-4618, 877-320-1235 (TTY: 711).
          <br /><br />
          Auxiliary aids and services, free of charge, are available to you. 877-320-1235 (TTY: 711)
          <br /><br />
          Humana provides free auxiliary aids and services, such as qualified sign language interpreters, video remote
          interpretation, and written information in other formats to people with disabilities when such auxiliary aids
          and services are necessary to ensure an equal opportunity to participate.
          <br /><br />
          This information is available for free in other languages. Please call our customer service number at
          877-320-1235 (TTY: 711). Hours of operation: 8 a.m. – 8 p.m. Eastern time.
          <br /><br />
          Español (Spanish): Llame al número indicado para recibir servicios gratuitos de asistencia lingüística.
          877-320-1235 (TTY: 711). Horas de operación: 8 a.m. a 8 p.m. hora del este.
          <br />
          繁體中文 (Chinese): 本資訊也有其他語言版本可供免費索取。請致電客戶服務部：877-320-1235（聽障專線：
          711）。辦公時間：東部時間上午 8 時至晚上 8 時。
          <br /><br />
          Other Pharmacies/Physicians/Providers are available in Humana’s network.<br /><br />
          The Humana Premier RX Plan (PDP) and the Humana Value RX Plan (PDP) Prescription Drug Plan Prescription Drug
          Plan (PDP) pharmacy network includes limited lower-cost, preferred pharmacies in urban areas of AR, CT, DE,
          IA, IN, KY, MA, MI, MN, MO, ND, NJ, NY, OH, RI, SD, TN,WI, WV; suburban areas of CT, DE, HI, IN, MA, MI, MN,
          MT, ND, NJ, NY, OH, PA, PR, RI, WI, WV; and rural areas of IA, MN, MT, ND, NE, SD, VT, WY. There are an
          extremely limited number of preferred cost share pharmacies in urban areas in the following states: DE, MI,
          MN, MS, and ND; suburban areas of MT and ND; and rural areas of ND. The lower costs advertised in our plan
          materials for these pharmacies may not be available at the pharmacy you use. For up-to-date information about
          our network pharmacies, including whether there are any lower-cost preferred pharmacies in your area, please
          call Customer Care at 1-800-281-6918 (TTY: 711) or consult the online pharmacy directory at Humana.com.
          <br /><br />
          Humana MyOption optional supplemental benefits (OSB) are only available to members of certain Humana Medicare
          Advantage (MA) plans. Members of Humana plans that offer OSBs may enroll in OSBs throughout the year. Benefits
          may change on January 1st each year.
          <br /><br />
          Enrollees must continue to pay the Medicare Part B premium, their Humana plan premium, and the OSB premium.
          <br /><br />
          Benefit(s) mentioned may be part of a special supplemental program for chronically ill members with one of the
          following conditions: Diabetes mellitus, Cardiovascular disorders, Chronic and disabling mental health
          conditions, Chronic lung disorders, Chronic heart failure. This is not a complete list of qualifying
          conditions. Having a qualifying condition alone does not mean you will receive the benefit(s). Other
          requirements may apply.
          <br /><br />
          The plans that we represent do not discriminate on the basis of race, color, national origin, age disability,
          or sex. To learn more about the plan’s non-discrimination policy, please <a
          href="https://cdn.coverright.com/1557.pdf" target={'_blank'}>click
          here</a>.
          <br /><br />
          Limitations on telehealth services, also referred to as virtual visits or telemedicine, vary by state. These
          services are not a substitute for emergency care and are not intended to replace your primary care provider or
          other providers in your network. Any descriptions of when to use telehealth services are for informational
          purposes only and should not be construed as medical advice. Please refer to your evidence of coverage for
          additional details on what your plan may cover or other rules that may apply.
          <br /><br />

          <b>Aetna Specific Disclaimers:</b>
          <br /><br />
          Aetna Medicare is a HMO, PPO plan with a Medicare contract. Our SNPs also have contracts with State Medicaid
          programs. Enrollment in our plans depends on contract renewal with Medicare.
          <br />
          <br />
          SilverScript is a Prescription Drug Plan with a Medicare contract marketed through Aetna Medicare. Enrollment
          in SilverScript depends on contract renewal with Medicare.
          <br /><br />
          Allina Health | Aetna Medicare is a PPO plan with a Medicare contract. Enrollment in our plans depends on
          contract renewal with Medicare.
          <br />
          Innovation Health Medicare is a HMO, PPO plan with a Medicare contract. Enrollment in our plans depends on
          contract renewal with Medicare.
          <br />
          <br />
          If referencing preferred pharmacies:
          <br /><br />
          The Aetna pharmacy network includes limited lower-cost, preferred pharmacies in applicable areas. The lower
          costs advertised in our plan materials for these pharmacies may not be available at the pharmacy you use. For
          up-to-date information about our network pharmacies, including whether there are any lower-cost preferred
          pharmacies in your area, please call 1-866-235-5660 (TTY: 711) or consult your online pharmacy directory at
          http://www.aetnamedicare.com/pharmacyhelp.
          <br /><br /><br /><br />
          © {moment().format('YYYY')} CoverRight Insurance Services Inc. All rights reserved.
          <br /><br />
          Last Updated: {moment().format('MM/DD/YYYY')}
        </Typography>
      </Box>
    </>
  </CRModal>;
}
