import React from 'react';
import { CRModal } from '@coverright/ui/shared';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, IconButton, Theme, Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      paddingRight: '30px',
      paddingLeft: '30px'
    }
  })
);

export function ComplienceMedicareDisclaimersModal(props: { open: boolean, onClose: () => void }) {
  const classes = useStyles();

  return <CRModal
    width={740}
    open={props.open}
    onClose={props.onClose}
    paperPadding={'30px 0px 40px'}
  >
    <>
      <IconButton sx={{
        position: 'absolute',
        top: 0,
        right: 0
      }} onClick={props.onClose}><img src={'/assets/img/close.svg'} /></IconButton>
      <Box className={classes.modalContent}>
        <Typography color={'textPrimary'} className={'bold fs-24'}>Medicare Disclaimers</Typography>
        <Typography color={'textPrimary'} className={'fs-14'}>
          <br />
          By submitting my phone number and personal information above and clicking ‘Continue’, I confirm that the phone
          number provided is my own, and I provide my express written consent to be contacted by CoverRight Insurance
          Services Inc. or one of its licensed insurance agents at the phone number I have provided above regarding various Medicare
          products including Medicare
          Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans and other marketing and
          informational content, through the use of text message, email and/or live, automated and pre-recorded
          telephone calls, AI generative voice calls, prerecorded messages played when a connection is made, or prerecorded voicemail messages
          even if my number is on a Do Not Call list. I further understand and agree that my
          communications with CoverRight Inc. are subject to CoverRight Inc.'s Privacy Policy and Terms of Use.
          <br /><br />
          This is a solicitation for insurance. Your information will be provided to a licensed agent for future
          contact.
          <br /><br />
          I understand my consents to receive text messages and telephone calls are not required to receive services
          from CoverRight Inc.; I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can
          revoke my consents at any time by emailing hello@coverright.com.
          <br /><br />
          CoverRight Insurance Services Inc. represents Medicare Advantage HMO, PPO and PFFS organizations that have a
          Medicare contract. Enrollment depends on the plan’s contract renewal with Medicare.
          <br /><br />
          Not all plans offer all of these benefits. Benefits may vary by carrier and location. Limitations and
          exclusions may apply.
          <br /><br />
          We do not offer every plan available in your area. Any information we provide is limited to those plans we do
          offer in your area. Please contact Medicare.gov or 1–800–MEDICARE 24 hours a day/7 days a week to get
          information on all of your options.
        </Typography>
      </Box>
    </>
  </CRModal>;
}
